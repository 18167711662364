import { useEffect, useRef, useState } from 'react'
import { isPlayingStore } from "./store"
import Header from "./Header"
import Shorts from './Shorts'


const GettingStarted = () => {

    const peopleDemo = [
        "ali",
        "aybuke",
    ]



    const isPlay = isPlayingStore((store) => store.isPlaying)
    const setIsPlay = isPlayingStore((store) => store.setIsplaying)

    const anounce = async (name) => {
        // console.log(name)
        let soundLink = await import(`./audio/${name}.wav`)
        let sound = new Audio(soundLink.default)
        sound.play()
        sound.onended = () => {
            setIsPlay(false)
        }
    }
    const handleAnounce = (item) => {
        if (!isPlay) {
            anounce(item)
        }
        setIsPlay(true)
    }

    const shortArr =
        [
            "cuma",
            "cuma15",
            "kapandi",
        ]

    return (
        <>
            <div id={"getting-started-wrapper"}>
                <div className="instruction-box">
                    <h1 className="instruction-title">Personel</h1>
                    <p className="instruction-title">Şubenize tanımlanan personeli çağırır</p>
                </div>
                <div
                    style={{
                        display: "flex", flexDirection: "row",
                        justifyContent: "center", alignItems: "center",
                        gap: "15px", backgroundColor: "#424242",
                        width: "100%", padding: "15px 0"
                    }}>
                    {peopleDemo.map((item) => {
                        return (
                            <div
                                onClick={() => handleAnounce(item)}
                                className='peopleBoxDemo'
                            >
                                <p>{item}</p>
                            </div>
                        )
                    })}
                </div>
                <div className="instruction-box">
                    <h1 className="instruction-title">Zil & Plaka Anonsu</h1>
                    <p className="instruction-title">Zil belirlediğiniz süre periyodunda anons yapar Plaka Anonsu ise gridiğiniz plakanın sahibini çağırır </p>
                </div>
                <Header />

                <div className="instruction-box">
                    <h1 className="instruction-title">Kısa Yollar</h1>
                    <p className="instruction-title">Sık kullanılıcak rutin anonslar için butonlar bulunur</p>
                </div>
                <div style={{ background: "#424242", display: "flex", flexDirection: "row", gap: "15px", justifyContent: "center", alignItems: "center", padding: "15px 0" }}>
                    {shortArr.map((item) => {
                        return (
                            <div
                                onClick={() => handleAnounce(item)}
                                className='shortButton'
                                style={{ background: "rgb(92, 92, 92)" }}
                            >
                                <p>{item}</p>
                            </div>
                        )
                    })}
                </div>
            </div >
        </>
    )
}

export default GettingStarted;
