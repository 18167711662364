import Header from './Header'
import Shorts from './Shorts';
import People from './People';
import { useEffect, useRef, useState } from 'react';
import { modalStore } from "./store"
const Password = () => {

    const setModal = modalStore((state) => state.setModal)

    const [isPasswordCorrect, setIsPasswordCorrect] = useState(false)

    // Şifre Değiştir --------------------------------------
    const building = [
        { password: 123456, link: "mavera" },
        { password: 1234567, link: "home" },
        { password: 1234, link: "mirzade" },
        { password: 1234, link: "demo1" }
    ]
    // -----------------------------------------------------


    const passwordRef = useRef()

    const signIn = () => {
        let wantedBuilding = window.location.pathname.split("/")[1].toLowerCase()
        let isCorrect = building.filter((item) => item.link == wantedBuilding)
        if (isCorrect.length > 0 && isCorrect[0].password == passwordRef?.current?.value) {
            setIsPasswordCorrect(true)
        } else {
            console.log(isCorrect, passwordRef.current.value)
            setModal({
                type: "error",
                status: true,
                message: "Şifre hatalı"
            })
        }
    }


    return (
        <>
            {
                isPasswordCorrect ?
                    <>
                        <Header />
                        <Shorts />
                        <People />
                    </> :
                    <>
                        <div className='password-box'>
                            <input ref={passwordRef} type='password' />
                            <button onClick={() => signIn()}>Giriş Yap</button>
                        </div>
                    </>
            }
        </>
    )
}

export default Password