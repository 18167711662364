import { useEffect, useState } from 'react'
import { anounce } from './utils'

import { isPlayingStore } from "./store"

const Shorts = () => {

    const isPlay = isPlayingStore((store) => store.isPlaying)
    const setIsPlay = isPlayingStore((store) => store.setIsplaying)

    const shortButtonClick = () => {
        return console.log("click")
    }
    useEffect(() => {
        document.querySelectorAll(".shortButton").forEach((item) => { item.onclick = () => shortButtonClick().toLowerCase() })
    }, [])

    const shortArr =
        [
            "cuma",
            "cuma15",
            "kapandi",
            "alt_karma",
            "indirim"
            
        ]

    const anounce = async (name) => {
        // console.log(name)
        let soundLink = await import(`./audio/${name}.wav`)
        let sound = new Audio(soundLink.default)
        sound.play()
        sound.onended = () => {
            setIsPlay(false)
        }
    }
    const handleAnounce = (item) => {
        if (!isPlay) {
            anounce(item)
        }
        setIsPlay(true)
    }
    return (
        <>
            <div id={"shortSection"}>
                <div className="title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M720 746V646H620v-60h100V486h60v100h100v60H780v100h-60ZM598 976q-123-51-196.5-160T328 574q0-98.882 42-187.441T489 236H322v-60h276v276h-60V273q-71 54-110.5 132.5T388 573q0 107.978 56.5 198.489Q501 862 598 910v66Z" /></svg>
                    <h1>Hazır Kısayollar</h1>
                </div>
                <div id={"shortsWrapper"}>
                    {shortArr.map((item) => {
                        return (
                            <div
                                onClick={() => handleAnounce(item)}
                                className='shortButton'
                            >
                                <p>{item}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default Shorts;
