import { create } from 'zustand'

const isPlayingStore = create((set) => ({
    isPlaying: false,
    setIsplaying: (update) => set({ isPlaying: update }),
}))

const modalStore = create((set) => ({
    modal: {
        type: "",
        status: false,
        message: "",
    },
    setModal: (update) => set({ modal: update }),
}));

export { isPlayingStore, modalStore }