import { useEffect, useState } from 'react'
import Modal from "./Modal"
import GettingStarted from "./GettingStarted"
import Password from './Password'

const App = () => {
  console.log = function () { }
  console.error = function () { }
  console.warn = function () { }

  const [loc, setLoc] = useState("")

  useEffect(() => {
    console.log(window.location.pathname, " sads")
    setLoc(window.location.pathname.split("/")[1])
  }, [])




  return (
    <div id={"App"} >
      {
        loc == "" ?
          <GettingStarted /> :
          <>
            <Modal />
            <Password />
          </>
      }
    </div>
  )
}

export default App
