import "./Modal.css"
import { userStore, modalStore } from './store';
import { useEffect } from "react";

const Modal = () => {

    const modal = modalStore((state) => state.modal)
    const setModal = modalStore((state) => state.setModal)

    useEffect(() => {
        if (modal.status) {
            setTimeout(() => {
                setModal({
                    type: modal.type,
                    status: false,
                    message: modal.message
                })
            }, 1000)
        }
    }, [modal])

    return (
        <div id={"modal-wrapper"}
            className={`${modal.status ? "open" : "close"}${modal.type == "success" ? "success" : "error"}`}>

            {
                modal.type == "success" ?
                    (<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m332 972-62-106-124-25q-11-2-18.5-12t-5.5-21l14-120-79-92q-8-8-8-20t8-20l79-91-14-120q-2-11 5.5-21t18.5-12l124-25 62-107q6-10 17-14t22 1l109 51 109-51q11-5 22-1.5t17 13.5l63 108 123 25q11 2 18.5 12t5.5 21l-14 120 79 91q8 8 8 20t-8 20l-79 92 14 120q2 11-5.5 21T814 841l-123 25-63 107q-6 10-17 13.5t-22-1.5l-109-51-109 51q-11 5-22 1t-17-14Zm105-349-73-76q-9-10-22-10t-23 9q-10 10-10 23t10 23l97 96q9 9 21 9t21-9l183-182q9-9 9-22t-10-22q-9-8-21.5-7.5T598 463L437 623Z" /></svg>) :
                    (<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M92 936q-9 0-15.652-4.125Q69.696 927.75 66 921q-4.167-6.6-4.583-14.3Q61 899 66 891l388-670q5-8 11.5-11.5T480 206q8 0 14.5 3.5T506 221l388 670q5 8 4.583 15.7-.416 7.7-4.583 14.3-3.696 6.75-10.348 10.875Q877 936 868 936H92Zm392.175-117q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm0-111q12.825 0 21.325-8.625T514 678V514q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T454 514v164q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Z" /></svg>)
            }

            <h3 className={"modal-message"}>
                {modal.message}
            </h3>
        </div>
    )
}

export default Modal;