import { useEffect, useRef, useState } from 'react'
import { isPlayingStore } from "./store"
const Header = () => {

    const isPlay = isPlayingStore((store) => store.isPlaying)
    const setIsPlay = isPlayingStore((store) => store.setIsplaying)
    const [time, setTime] = useState(new Date())

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date())
        }, 1000);

        return () => clearInterval(interval);
    }, []);


    const [first, setFirst] = useState(null)
    const [second, setSecond] = useState(null)
    const [last, setLast] = useState(null)

    const [plate, setPlate] = useState(false)

    const firstRef = useRef()
    const secondRef = useRef()
    const lastRef = useRef()


    const sesleriOynat = async (arr) => {
        setIsPlay(true)
        let index = 0

        const a = async () => {
            let soundLink = await import(`./audio/${arr[index]}.wav`)
            let ses = new Audio(soundLink.default)
            ses.play()
            ses.playbackRate = 1.5
            ses.onended = function () {
                if (index < arr.length - 1) {
                    a(index++)
                } else {
                    setIsPlay(false)
                }
            }
        }

        a()
    }





    function basamakAyir(sayi) {
        var birler = sayi % 10;
        var onlar = Math.floor((sayi % 100) / 10);
        var yuzler = Math.floor((sayi % 1000) / 100);
        var binler = Math.floor(sayi / 1000);

        let a = [binler * 1000, yuzler * 100, onlar * 10, birler]
        let A = []
        if (onlar == 0 && yuzler == 0 && binler == 0 || sayi.length == 2 && onlar == 0) {
            A = [0, birler]
        } else {
            A = a.filter((item) => item != 0)
        }
        console.error("binler", A)
        return A
    }
    function plateAnounce() {
        setFirst(firstRef.current.value)
        setSecond(secondRef.current.value)
        setLast(lastRef.current.value)


        let A = basamakAyir(firstRef.current.value)
        let B = Array.from(secondRef.current.value)
        let C = basamakAyir(lastRef.current.value)

        let merged = ["zil_plaka", ...A, ...B, ...C, "plakali"]
        console.error(merged)
        console.log("asdsdadas")

        if (isPlay) return
        sesleriOynat(merged)
    }

    useEffect(() => {
        console.log(first, secondRef, lastRef)
    }, [first, secondRef, lastRef])

    const handleUpperCase = () => {
        secondRef.current.value = secondRef.current.value.toUpperCase()
    }

    const [ring, setRing] = useState(false)
    const [selectedTime, setSelectedTime] = useState(null)

    const callRing = async () => {
        if (isPlay) return

        setIsPlay(true)
        let ringLink = await import("./audio/alt_karma.wav");
        let ses = new Audio(ringLink.default);
        ses.play()
        ses.onended = () => setIsPlay(false)
    }
    const callRing2 = async () => {
        if (isPlay) return

        setIsPlay(true)
        let ringLink = await import("./audio/alt_karma.wav");
        let ses = new Audio(ringLink.default);
        ses.play()
        ses.onended = () => setIsPlay(false)
    }




    useEffect(() => {
        if (selectedTime != null) {
            callRing()
            const ring = setInterval(() => {
                callRing()
            }, 1000 * 60 * selectedTime);

            return () => clearInterval(ring);
        }
    }, [selectedTime]);
    useEffect(() => {
        if (selectedTime != null) {
            callRing2()
            const ring = setInterval(() => {
                callRing2()
            }, 1000 * 60 * selectedTime);

            return () => clearInterval(ring);
        }
    }, [selectedTime]);

    const timeOneRef = useRef()
    const timeTwoRef = useRef()
    const timeThirdRef = useRef()

    return (
        <>
            <div id={"header"}>
                <div className={"buttonsWrapper"}>
                    <div onClick={() => setRing(!ring)} className={"ringButton"}>
                        <p>Zil</p>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M160 856v-60h84V490q0-84 49.5-149.5T424 258v-29q0-23 16.5-38t39.5-15q23 0 39.5 15t16.5 38v29q81 17 131 82.5T717 490v306h83v60H160Zm320 120q-32 0-56-23.5T400 896h160q0 33-23.5 56.5T480 976Z" /></svg>
                    </div>
                    <div className={ring ? "ringTimeWrapper" : "displayNone"}>
                        <button ref={timeOneRef} onClick={() => setSelectedTime(timeOneRef.current?.value)} className={selectedTime == timeOneRef.current?.value ? "selectedTime" : ""} value={5} >{timeOneRef.current?.value + " " + "dk"}</button>
                        <button ref={timeTwoRef} onClick={() => setSelectedTime(timeTwoRef.current?.value)} className={selectedTime == timeTwoRef.current?.value ? "selectedTime" : ""} value={10} >{timeTwoRef.current?.value + " " + "dk"}</button>
                        <button ref={timeThirdRef} onClick={() => setSelectedTime(timeThirdRef.current?.value)} className={selectedTime == timeThirdRef.current?.value ? "selectedTime" : ""} value={15} >{timeThirdRef.current?.value + " " + "dk"}</button>
                    </div>
                    <div onClick={() => setPlate(!plate)} className={"plateButton"}>
                        <p>Plaka Anons</p>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M200 852v54q0 12.75-8.625 21.375T170 936h-20q-12.75 0-21.375-8.625T120 906V582l85-256q5-14 16.5-22t26.5-8h464q15 0 26.5 8t16.5 22l85 256v324q0 12.75-8.625 21.375T810 936h-21q-12.75 0-21.375-8.625T759 906v-54H200Zm3-330h554l-55-166H258l-55 166Zm82.765 220Q309 742 324.5 726.25T340 688q0-23.333-15.75-39.667Q308.5 632 286 632q-23.333 0-39.667 16.265Q230 664.529 230 687.765 230 711 246.265 726.5q16.264 15.5 39.5 15.5ZM675 742q23.333 0 39.667-15.75Q731 710.5 731 688q0-23.333-16.265-39.667Q698.471 632 675.235 632 652 632 636.5 648.265q-15.5 16.264-15.5 39.5Q621 711 636.75 726.5T675 742Z" /></svg>
                    </div>
                    <div className={plate ? 'plateInputsWrapper' : 'displayNone'}>
                        <input maxLength={3} type='text' ref={firstRef} placeholder='il' />
                        <input maxLength={4} onChange={() => handleUpperCase()} type="text" ref={secondRef} placeholder='harf' />
                        <input maxLength={4} type="text" ref={lastRef} placeholder='kod' />
                        <button onClick={() => plateAnounce()}>Anons</button>
                    </div>
                </div>
                <div className='timeAndClock'>
                    <p>{`${time.getDate()} / ${time.getMonth() + 1} / ${time.getFullYear()} | ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`}</p>
                </div>
            </div >
        </>
    )
}

export default Header;
