import { useEffect, useState } from 'react'
import { anounce } from './utils'

import { isPlayingStore } from "./store"

const People = () => {


    const isPlay = isPlayingStore((store) => store.isPlaying)
    const setIsPlay = isPlayingStore((store) => store.setIsplaying)

    const anounce = async (name) => {
        // console.log(name)
        let soundLink = await import(`./audio/${name}.wav`)
        let sound = new Audio(soundLink.default)
        sound.play()
        sound.onended = () => {
            setIsPlay(false)
        }
    }
    const handleAnounce = (item) => {
        if (!isPlay) {
            anounce(item)
        }
        setIsPlay(true)
    }


    const shortButtonClick = () => {
        return console.log("click")
    }
    useEffect(() => {
        document.querySelectorAll(".shortButton").forEach((item) => { item.onclick = () => shortButtonClick() })
    }, [])


    let feMalePeopleArr = [
        // "aybuke",
        // "Aybüke",
        // "ayse",
        // "aysegul",
        // "Dilara",
        // "fatma",
        // "gizem",
        // "gulsah",
        // "Gülşah",
        // "gulsen",
        // "merve",
        // "Meryem",
        // "ruveyda",
        // "tuba",
        // "Tuğba",
        // "zeynep",
    ]

    let malePeopleArr =
        [
            // "ali",
            // "burak",
            // "emin",
            // "emincan",
            // "erol",
            // "furkan",
            // "halil",
            // "hamit",
            // "muhammed",
            // "Murat",
            // "oguzhan",
            // "omer",
            // "Ramazan",
            // "Talha",
            // "yakup",
            // "Yusuf",
        ]

    const [female, setFemale] = useState([])
    const [male, setMale] = useState([])

    // Personel Değiştir ------------------------------------------------------------------------
    useEffect(() => {
        console.log(window.location.pathname)
        let loc = window.location.pathname.split("/")[1].toLowerCase()

        const sube1F = ["AYŞE", "ZEYNEP","FATMA","SUDE","EZGİ","ZEHRA","ESMANUR","EFSA","HİLAL",]
        const sube1M = ["EMİNCAN", "MUHAMMET", "FURKAN", "RAMAZAN", "EMİN","YUSUF","MAHMUT","ÖMER","EYYÜP" ,]

        const sube2F = ["ESRA", "NAZ", "AYŞEGÜL", "SARE", "GÜLŞAH", "BEYZA","DİLARA","NİSANUR","ŞEVVAL","ZEYNEP","ESRANUR","BÜŞRA","TÜM",]
        const sube2M = ["BURAK", "ÖMER", "FURKAN", "YAKUP", "EROL","AKİF","SEYFİ",]


        const mirzadeF = ["ESRA", "AYBÜKE", "AYŞEGÜL", "MERYEM", "TUBA", "GÜLŞAH", "BEYZA","DİLARA","SARE"]
        const mirzadeM = ["İSA", "ÖMER", "ALİ", "YAKUP",]


        const sube4F = ["emincan", "erol", "furkan", "halil", "hamit", "muhammed", "Murat", "oguzhan", "omer", "Ramazan", "Talha", "yakup", "Yusuf"]
        const sube4M = ["emincan", "erol", "furkan", "halil", "hamit", "muhammed", "Murat", "oguzhan", "omer", "Ramazan", "Talha", "yakup", "Yusuf"]

        if (loc == "mavera") {
            setFemale(sube1F)
            setMale(sube1M)
        } if (loc == "home") {
            setFemale(sube2F)
            setMale(sube2M)
        } if (loc == "mirzade") {
            setFemale(mirzadeF)
            setMale(mirzadeM)
        } if (loc == "sube4") {
            setFemale(sube4F)
            setMale(sube4M)
        }
    }, [])
    // -----------------------------------------------------------------------------------





    useEffect(() => {
        document.querySelectorAll(".peopleBox").forEach((box) => {
            box.style.backgroundColor = `rgb(${Math.floor(Math.random() * 101)},${Math.floor(Math.random() * 160)},${Math.floor(Math.random() * 101)})`
        })
    }, [male, female])

    return (
        <>
            <div id={"peopleSection"}>
                <div className='title'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M0 816v-53q0-38.567 41.5-62.784Q83 676 150.376 676q12.165 0 23.395.5Q185 677 196 678.652q-8 17.348-12 35.165T180 751v65H0Zm240 0v-65q0-32 17.5-58.5T307 646q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-19.861-3.5-37.431Q773 696 765 678.727q11-1.727 22.171-2.227 11.172-.5 22.829-.5 67.5 0 108.75 23.768T960 763v53H780Zm-480-60h360v-6q0-37-50.5-60.5T480 666q-79 0-129.5 23.5T300 751v5ZM149.567 646Q121 646 100.5 625.438 80 604.875 80 576q0-29 20.562-49.5Q121.125 506 150 506q29 0 49.5 20.5t20.5 49.933Q220 605 199.5 625.5T149.567 646Zm660 0Q781 646 760.5 625.438 740 604.875 740 576q0-29 20.562-49.5Q781.125 506 810 506q29 0 49.5 20.5t20.5 49.933Q880 605 859.5 625.5T809.567 646ZM480 576q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600 456q0 50-34.5 85T480 576Zm.351-60Q506 516 523 498.649t17-43Q540 430 522.851 413t-42.5-17Q455 396 437.5 413.149t-17.5 42.5Q420 481 437.351 498.5t43 17.5ZM480 756Zm0-300Z" /></svg>
                    <h1>Personel İsim'e Göre Anons</h1>
                </div>
                <div id={"peopleWrapperWrapper"} >
                    <div id={"peopleWrapperLeft"}>
                        {male.map((item) => {
                            return (
                                <div
                                    onClick={() => handleAnounce(item)}
                                    className='peopleBox'
                                >
                                    <p>{item}</p>
                                </div>
                            )
                        })}
                    </div>

                    <div className='seperator'></div>

                    <div id={"peopleWrapperRight"}>
                        {female.map((item) => {
                            return (
                                <div
                                    onClick={() => handleAnounce(item)}
                                    className='peopleBox'
                                >
                                    <p>{item}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default People;
